import { Component, OnInit } from '@angular/core';
import { L } from '@ic2/ic2-lib';
import { EMAObjectiveLiteDTO, TeamEMAORService } from 'common';

@Component({
    selector: 'hiji-equipe-kpi',
    templateUrl: './equipe-kpi.component.html',
    styleUrls: ['./equipe-kpi.component.scss'],
    standalone: false
})
export class EquipeKpiComponent implements OnInit {
  data: EMAObjectiveLiteDTO[];
  loading: boolean = true;
  constructor(private teamEMAORService: TeamEMAORService) {}

  ngOnInit(): void {
    this.teamEMAORService.getEMAObjective().subscribe(
      (data) => {
        this.data = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
